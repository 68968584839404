import React, { useEffect, useState } from 'react';
import styles from './navBar.module.css';
import { Box, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { folder_id_action } from '../../store/folderId/folderIdAction';
import { useDispatch } from 'react-redux';

const NavBar = ({ expand, isFetched }) => {
  const [active, setActive] = useState('Home');
  const [toggle, setToggle] = useState(true);
  const [loader, setLoader] = useState(false);
  const [devToolsOpen, setDevToolsOpen] = useState(false);

  const id = useSelector((state) => state?.document?.value?.OrgId);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);

  const location = useLocation();
  const dispatch = useDispatch();

  const nav = [
    { name: 'Home', value: `c${id}/home`, img: 'House.svg', activeImg: 'home-active.svg' },
    {
      name: 'My Documents',
      value: `c${id}/my-documents`,
      img: 'FolderOpen.svg',
      activeImg: 'mydocuments-active.svg',
    },
    {
      name: 'Templates',
      value: `c${id}/templates`,
      img: 'FileText.svg',
      activeImg: 'template-active.svg',
    },

    {
      name: 'Send For Sign',
      value: `c${id}/send-sign`,
      img: 'PaperPlaneTilt .svg',
      activeImg: 'sign-others-active.svg',
    },
    {
      name: 'Sign Myself',
      value: `c${id}/sign-myself`,
      img: 'PencilLine.svg',
      activeImg: 'sign-myself-active.svg',
    },
    {
      name: 'Contacts',
      value: `c${id}/contacts`,
      img: 'Users.svg',
      activeImg: 'contacts-active.svg',
    },
    {
      name: 'Integrations',
      value: `c${id}/integrations`,
      img: 'integration.svg',
      activeImg: 'integration-active.svg',
    },
    {
      name: 'Recent Activity',
      value: `c${id}/activity`,
      img: 'recent-activity.svg',
      activeImg: 'recent-activity-active.svg',
    },
    {
      name: 'Settings',
      value: `c${id}/settings`,
      img: 'Settings.svg',
      activeImg: 'settings-active.svg',
    },
  ];

  useEffect(() => {
    let menu_bar = show_integeration?.properties_info && JSON.parse(show_integeration?.properties_info)[0]?.MENU_BAR;
    if (expand === null) {
      setToggle(menu_bar && !JSON.parse(menu_bar));
    } else {
      setToggle(!expand);
    }
  }, [expand, show_integeration?.properties_info]);

  useEffect(() => {
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
    let navPath = nav?.find((v, i) => `/${v.value}` === location.pathname);
    if (reload[0] === 'reload' && navPath) {
      setActive(navPath?.name);
    } else if (isFetched && navPath) {
      setActive(navPath?.name);
    } else if (reload[0] === 'reload' && (window?.location?.href?.split('/')[5] === 'send-sign-details' || window?.location?.href?.split('/')[5] === 'sign-myself-details')) {
      if (window?.location?.href?.split('/')[5] === 'send-sign-details') {
        setActive('Send For Sign');
      } else setActive('Sign Myself');
    }

    if (window.location.href.includes('upload')) setActive('My Documents');
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 400);
  }, []);

  useEffect(() => {
    const checkDevToolsBottom = () => {
      // const isDevToolsOpen = window.outerWidth - window.innerWidth > 150 || window.outerHeight - window.innerHeight > 150; --> both side devtools
      const isDevToolsBottomOpen = window.outerHeight - window.innerHeight > 100;
      setDevToolsOpen(isDevToolsBottomOpen);
    };

    checkDevToolsBottom();

    window.addEventListener('resize', checkDevToolsBottom);
    return () => window.removeEventListener('resize', checkDevToolsBottom);
  }, []);

  const handleNavigation = (nav) => {
    setActive(nav);
    if (active === 'My Documents' || active === 'Home') dispatch(folder_id_action(null));
  }

  const show_features = show_integeration?.show_features?.length ? JSON.parse(show_integeration?.show_features)[0]?.features : [];
  return (
    <Box>
      {isFetched &&
        <Box className={`${styles.nav_wrap} ${toggle && styles.nav_expand}`}>
          <NavLink to={`c${id}/home`} className={styles.centilio_title} style={{ marginLeft: toggle ? '5px' : '20px' }}>
            <img
              src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
              className='welcome-img'
              alt='welcome-img'
              style={{ width: '30px', height: '30px' }}
            />

            {!toggle && (
              <Typography className={styles.centilio_sign}>
                Centilio Sign
              </Typography>
            )}
          </NavLink>
          <Box className={styles.nav_titles} sx={{ overflowY: devToolsOpen ? 'scroll' : 'hidden', height: devToolsOpen ? 'calc(100vh - 70px)' : '100vh' }}>
            {show_integeration?.user_permission && nav?.map((item, i) => {
              const hasPermission = show_integeration?.user_permission[i] ? show_integeration?.user_permission[i]?.read === 1 : true;
              // item?.name === 'Recent Activity' && !show_features?.AUDIT_LOG && show_features?.hasOwnProperty('AUDIT_LOG') ? null :
              return (
                <Box key={item.name} sx={{ marginTop: hasPermission ? toggle ? '40px' : '25px' : '0px' }}>
                  {!hasPermission ? null :
                    loader ?
                      <NavLink
                        to={item.value}
                        className={`${styles.nav_item} ${active === item.name && !toggle && styles.active_nav}`}
                        style={
                          {
                            ...toggle
                              ? {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '5px 21px',
                                translate: '-3px',
                                borderRadius: '3px'
                              }
                              : {}, ...active === item.name ? { backgroundColor: "rgba(189,197,209,.2)", color: 'var(--sidebar-icon-color)' } : { color: 'var(--table-font-color)' }
                          }
                        }
                        key={item.name}
                        onClick={() => handleNavigation(item?.name)}
                      >
                        {active === item.name ? (
                          <Tooltip title={toggle ? item.name : null} placement="right">
                            <img
                              className={styles.nav_name}
                              style={{ ...toggle ? { margin: 'unset' } : {}, }}
                              alt={item.name}
                              src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/' + item.activeImg}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title={toggle ? item.name : null} placement="right">
                            <img
                              className={styles.nav_name}
                              style={toggle ? { margin: 'unset' } : {}}
                              alt={item.name}
                              src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/' + item.img}
                            />
                          </Tooltip>

                        )}
                        {!toggle && <p style={{ fontSize: '14px', }}>{item.name}</p>}
                      </NavLink> : <Box className={styles.skeleton_wrapper}><Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)' }} key={item.name} variant="rectangular" width="100%" height="40px" /></Box>}
                </Box>
              )
            })}
          </Box>
        </Box>}
    </Box >
  );
};

export default NavBar;
