import { Button, Typography, Box, Modal, Fade, IconButton, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getChannelConnect, getChannelDisconnect, getChannelFiles } from "../../Api/UploadChannel";
import AppHeader from "../../component/appHeader/AppHeader";
import { integration_action } from "../../store/integrations/integrationAction";
import { toggleLoader } from "../../store/loader/loaderReducer";
import { Navigation } from "../../utils/handleNavigation";
import './Integrations.css';
import { ClearIcon } from "@mui/x-date-pickers";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    p: 4,
};

const initialState = [
    { name: 'Google Drive', channel: 1, describe: 'Upload documents directly from Google drive to here.', img: 'Integrations/google-drive.png' },
    { name: 'Dropbox', channel: 3, describe: 'Upload documents directly from Dropbox to here.', img: 'Integrations/dropbox.png' },
    { name: 'Box', channel: 4, describe: 'Upload documents directly from Box to here.', img: 'Integrations/box.png' },
    { name: 'Monday', channel: 5, describe: 'Create documents directly from Monday to here.', img: 'Integrations/monday.png' },
    { name: 'Pipedrive', channel: 2, describe: 'Upload documents directly from pipedrive to here.', img: 'Integrations/pipedrive.png' },
    { name: 'Request CRM', describe: '', img: 'Integrations/crm.png' },
];

const Integrations = () => {

    const [connected, setConnected] = useState(false);
    const [channel, setChannel] = useState([]);
    const [channelId, setChannelId] = useState({});
    const [disButton, setDisButton] = useState(null);
    const [move, setMove] = useState(null);
    const [channelDetails, setChannelDetails] = useState([]);
    const [is_closed, setIs_closed] = useState(true);
    const [integration, setIntegration] = useState(initialState)
    const [request, setRequest] = useState({ request_reason: "", comments: "" });
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const navPath = Navigation();
    const state = useSelector((state) => state.document.value);
    const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
    const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { integration_information, is_connected } = useSelector((state) => state.integrationDetails.value);

    useEffect(() => {
        if ((is_connected && integration_information?.length) || is_closed) handleCheckConnected();
    }, [integration_information, is_connected]);

    useEffect(() => {
        const show_features = show_integeration?.show_features?.length ? JSON.parse(show_integeration?.show_features)[0]?.features : [];
        // if (!show_features?.GOOGLE_DRIVE && show_features?.hasOwnProperty('GOOGLE_DRIVE')) {
        //     setIntegration(current =>
        //         current.filter(item => {
        //             return item.name !== 'Google Drive';
        //         }),
        //     );
        // };

        // if (!show_features?.CRM_MONDAY && show_features?.hasOwnProperty('CRM_MONDAY')) {
        //     setIntegration(current =>
        //         current.filter(item => {
        //             return item.name !== 'Monday';
        //         }),
        //     );
        // };

        if (!show_features?.CRM_PIPEDRIVE && show_features?.hasOwnProperty('CRM_PIPEDRIVE')) {
            setIntegration(current =>
                current.filter(item => {
                    return item.name !== 'Pipedrive';
                }),
            );
        };
    }, []);

    const handleCheckConnected = () => {
        let arr = [];
        let obj = {};
        let channel_id = {};
        integration_information?.map((list, i) => {
            if (arr?.indexOf(list.Channel_Type) === -1) {
                obj = { ...obj, [list.Channel_Type]: true };
                channel_id = { ...channel_id, [list.Channel_Type]: list.Channel_Id ?? list.Account_Id };
                arr.push(list.Channel_Type);
            }
        });
        setChannel(arr);
        setConnected(obj);
        setChannelId(channel_id);
        setChannelDetails(integration_information);
    };

    const handleConnect = (type, re_connect) => {
        if (type === 2) window.open(process.env.REACT_APP_PIPEDRIVE, '_blank');
        else {
            dispatch(toggleLoader(true));
            setChannel(pre => [...pre, type]);
            let options = { org_id: state?.OrgId, channel_type: type };
            getChannelConnect(options, (res) => {
                dispatch(toggleLoader(false));
                let data = type === 5 ? res.data.data : res.data['response data'];
                const childWin = window.open(data, "_blank");
                let userAgent = navigator.userAgent;
                let winClosed = setInterval(function () {
                    if (childWin.closed) {
                        clearInterval(winClosed);
                        handleChannelType(options, type, re_connect);
                        setIs_closed(false);
                    }
                    else if (childWin.length === 0 && !(userAgent.indexOf("Firefox") !== -1)) {
                        if (childWin.location.href.includes('callback')) {
                            childWin.close()
                        }
                    } else if (childWin.length === 0 && userAgent.indexOf("Firefox") !== -1) {
                    }
                }, 250);
            }, (err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
        }
    };

    const handleChannelType = (options, type, re_connect) => {
        dispatch(toggleLoader(true));
        getChannelFiles(options, (res) => {
            let data = res.data;
            if (data.client_access_token) {
                let list = { Channel_Type: type, Channel_Id: data.channel_id };
                let disconnect = channelDetails?.find((s, i) => s.Channel_Id === data.channel_id);
                if (disconnect) {
                    const _channelDetails = channelDetails?.map((item, i) => {
                        if (item?.Channel_Id === data.channel_id) {
                            return { ...item, Status: 1 }
                        }
                        return item;
                    });
                    setChannelDetails(_channelDetails);
                } else {
                    setChannelDetails([...channelDetails, list]);
                };

                if (re_connect === 'reconnect') {
                    const reConnect = channelDetails?.filter((list, i) => list?.Channel_Type !== type);
                    setChannelDetails([...reConnect, list]);
                    dispatch(integration_action({ integration_information: [...reConnect, list] }));
                } else {
                    if (channelDetails?.length) {
                        let obj = [...channelDetails];
                        obj.push(list);
                        dispatch(integration_action({ integration_information: obj }));
                    } else {
                        dispatch(integration_action({ integration_information: [list] }));
                    }
                }
                setChannelId({ ...channelId, [type]: data?.channel_id ?? data?.account_id });
                setConnected({ ...connected, [type]: data.client_access_token });
            }
            dispatch(toggleLoader(false));
        }, (err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
        })
    };

    const handleDisconnect = (type) => {
        dispatch(toggleLoader(true));
        let data;
        if (type === 5) data = { org_id: state?.OrgId, channel_type: type, account_id: channelId[type] };
        else data = { org_id: state?.OrgId, channel_type: type, channel_id: channelId[type] };
        getChannelDisconnect(data, (res) => {
            let obj = channel?.indexOf(type);
            let data = channel?.filter((k, i) => i !== obj);
            let details = channelDetails?.filter((s, idx) => s?.Channel_Type !== type);
            dispatch(integration_action({ integration_information: details ?? null }));
            setChannel(data);
            setConnected({ ...connected, [type]: false });
            let remove_channel = { ...channelId };
            delete obj[type];
            setChannelId(remove_channel);
            dispatch(toggleLoader(false));
        }, (err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
        });
    };

    const handleInstall = (name) => {
        if (name === 'Monday') {
            window.open(process.env.REACT_APP_INSTALL_MONDAY, '_blank');
        }
    };

    const handleRequest = () => {
        if (request?.request_reason.length !== 0) {
            handleClose()
            setErrors({});

            let data = {
                request_reason: request.request_reason, comments: request.comments,
            }
            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_INTEGRATION_REQUEST, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Successfully send your request');
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    let ErrorDetails = err?.response?.data?.details
                    let ResErrorDetails = err?.response?.data?.error?.details
                    ResErrorDetails ? toast.error(err?.response?.data?.error?.details) : ErrorDetails ? toast.error(err?.response?.data?.details) : toast.error(err?.response?.data);
                })
        }
        else {
            setErrors({ request_reason: 'Enter your request Reason' })
        }
        setRequest({ ...request, request_reason: '', comments: "" })
    };

    let expired = {};
    return (
        <Box className="integration-container">
            <AppHeader
                btnText='Upload'
                moduleText='Connected Apps'
                onBtnClick={() => navPath('/upload')}
                rolePermissions={true}
                checkAdmin={admin}
            />
            <Box className='integration_wrapper'>
                <Box className='integration_box_body'>
                    {integration?.map((item, index) => {
                        let channel_connect = connected[item?.channel] && channel?.find((channel) => channel === item?.channel);
                        channelDetails?.map((s, i) => channel_connect && s.Status === 2 ? expired[s.Channel_Type] = s.Channel_Type : null);
                        return (
                            <Box key={index} className='integration_body' onMouseOver={() => setDisButton(index)} onMouseOut={() => [setDisButton(null), setMove(null)]}>
                                <Box sx={{ '&:hover': { opacity: channel_connect && disButton === index ? '0.4' : '1.0' }, padding: '20px !important', position: 'relative', opacity: move === index ? '0.4' : '1.0' }}>
                                    <Box className='integration_box'>
                                        <img
                                            src={process.env.REACT_APP_IMAGE_URL + item?.img}
                                            className='dropbox-img'
                                            alt='dropbox'
                                        />
                                        {/* {item?.name === 'Monday' ?
                                            <Button variant="outlined" className="box-connect" onClick={() => handleInstall(item?.name)}>Install</Button> : */}
                                        {item?.name === 'Request CRM' ? <Button variant="outlined" className="box-connect" onClick={() => handleOpen()}>Request</Button> :
                                            expired[item?.channel] && channel_connect ? <Button variant="outlined" className='expired_button'>Expired</Button> :
                                                <Button variant="outlined" style={{ cursor: channel_connect ? 'not-allowed' : 'pointer' }} className={channel_connect ? 'box-connected' : "box-connect"} onClick={() => !channel_connect && handleConnect(item?.channel)}>{channel_connect ? 'Connected' : 'Connect'}</Button>}
                                    </Box>
                                    <Box className="integration_text">
                                        <Typography className="top_text">{item?.name}</Typography>
                                        <Typography className="bottom_text">{item?.describe}</Typography>
                                    </Box>
                                </Box>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={open}
                                    // onClose={handleClose}
                                    className="request_crm"
                                >
                                    <Fade in={open}>
                                        <Box sx={style}>
                                            <Box sx={{ textAlign: 'end' }}>
                                                <IconButton onClick={handleClose} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <Box sx={{ padding: '15px 0px' }} id="transition-modal-title" variant="h6" component="h2">
                                                    Integration Request
                                                </Box>
                                                <Box sx={{ gap: '10px', display: 'grid' }}>
                                                    {/* <Box id="transition-modal-title" >
                                                        Email Id <span style={{ color: 'red' }}>*</span>
                                                    </Box>
                                                    <TextField fullWidth id="fullWidth" placeholder="Enter Email id" /> */}
                                                    <Box id="transition-modal-title" >
                                                        Which integration are you missing?<span style={{ color: 'red' }}>*</span>
                                                    </Box>
                                                    <TextField error={errors?.request_reason}
                                                        helperText={errors?.request_reason && errors?.request_reason} fullWidth id="fullWidth" onChange={(e) => setRequest({ ...request, request_reason: e.target.value })} />
                                                    <Box id="transition-modal-title" >
                                                        Comments
                                                    </Box>
                                                    <TextField
                                                        multiline
                                                        rows={4}
                                                        fullWidth id="fullWidth"
                                                        onChange={(e) => setRequest({ ...request, comments: e.target.value })} />
                                                </Box>
                                                <Box sx={{ padding: '15px 0px' }}>
                                                    <Button
                                                        variant="contained"
                                                        // sx={{ backgroundColor: '#00c184' }}
                                                        // disabled={loader}
                                                        disableElevation
                                                        disableRipple
                                                        fullWidth
                                                        onClick={() => handleRequest()}
                                                    >
                                                        Request
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Fade>
                                </Modal>
                                <Box className='disconnect' onMouseOver={() => setMove(index)} sx={{ opacity: disButton === index && '1 !important', zIndex: '9999999 !important' }}>
                                    {channel_connect &&
                                        <Button variant="outlined" className={expired[item?.channel] ? 'reconnect_button' : 'disconnect_button'} onClick={() => expired[item?.channel] ? handleConnect(item?.channel, 'reconnect') : handleDisconnect(item?.channel)}>{expired[item?.channel] ? 'Reconnect' : 'Disconnect'}</Button>
                                    }
                                </Box>

                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
};

export default Integrations;