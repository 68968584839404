import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toggleLoader } from "../store/loader/loaderReducer";
import { signatureId_action } from "../store/signatureId/signatureIdAction";
import SignatureField from "./SignatureField";
import './styles/PDFData.css';

const colors = ['#000', '#1e87ca', '#ac3235', '#108473'];
const textColor = ['black', 'dodgerblue', 'brown', 'seagreen'];

const SignatureImage = (props) => {
  const [getImage, setGetImage] = useState({});
  const [type, setType] = useState('draw');
  const [color, setColor] = useState('#000');
  const [font, setFont] = useState('Square Peg');
  const [getText, setGetText] = useState(null);
  const [imgType, setImgType] = useState('');

  const dispatch = useDispatch();
  const document = useSelector((state) => state.document.value);
  const signature_id = useSelector((state) => state.signature_id.value);

  const { getOpenSignature, openSignature, getWidth, openSign } = props;

  useEffect(() => {
    fetchSign();
  }, []);

  const fetchSign = () => {
    axios.get(process.env.REACT_APP_SIGNSETTING, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: { org_id: document?.OrgId },
    })
      .then((res) => {
        if (!res.data) {
          setGetImage(null);
        } else {
          dispatch(signatureId_action(res?.data?.settings?.sign_image));
          if (!res?.data?.settings?.sign_image) getOpenSignature(true);
          else handleSignSetting(res?.data?.settings?.sign_image);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
  };

  const handleSignSetting = (imgId) => {
    let params = { file_id: imgId, org_id: document?.OrgId };
    axios.get(process.env.REACT_APP_SIGNSETTING_DRIVE, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: params, responseType: 'blob' })
      .then((res) => {
        let imgUrl = URL.createObjectURL(res.data);
        const img = new Image();
        img.src = imgUrl;
        img.onload = () => {
          let aspectRatio = img.width / img.height;
          const newWidth = aspectRatio * 40;
          getWidth(Math.round(newWidth));
        };
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const dataURLtoBlob = (dataURL) => {
    let arr = dataURL.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let b64 = atob(arr[1]);
    let n = b64.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = b64.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };


  const handleSubmit = async (value) => {
    let img = type === 'upload' ? imgType : 'sign.jpeg';
    const blob = dataURLtoBlob(value);
    let file = new File([blob], img);

    if (type === 'type') {
      let textDetails = {
        text: getText,
        font_size: 60,
        font_color: textColor[colors.indexOf(color)],
        font_family: font === 'Square Peg' ? 'square_peg' : font === 'Caveat' ? 'caveat' : 'bad_script'
      };

      dispatch(toggleLoader(true));
      axios.post(process.env.REACT_APP_TEXT_SIGNATURE, textDetails, { withCredentials: process.env.REACT_APP_CREDENTIALS, responseType: 'blob' })
        .then((res) => {
          let file_ = new File([res.data], img);
          handleSignSubmit({ file: file_, org_id: document?.OrgId });
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    } else {
      handleSignSubmit({ file: file, org_id: document?.OrgId });
    }
  };

  const handleSignSubmit = (form) => {
    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_SIGNSETTING_DRIVE, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        if (res.status === 200) {
          getOpenSignature(false);
          dispatch(toggleLoader(false));
          dispatch(signatureId_action(res?.data?.response?.Sign_Image));
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
    getOpenSignature(false);
  };

  return (
    <Box>
      {signature_id &&
        <img
          width='100%'
          height='100%'
          src={`${process.env.REACT_APP_SIGNSETTING_DRIVE}?file_id=${signature_id}&org_id=${document?.OrgId}`}
          alt='signature'
          className='signature_img'
        />}
      <SignatureField
        toggleNewContact={(!signature_id && openSignature) || openSign}
        getText={(e) => setGetText(e)}
        getGetImage={(e) => setGetImage(e)}
        getToggleNewContact={(value) => getOpenSignature(value, 'close_icon')}
        handleSubmit={handleSubmit}
        getType={(type) => setType(type)}
        getColor={(color) => setColor(color)}
        getFont={(font) => setFont(font)}
        getImageType={(imageType) => setImgType(imageType)}
      />
    </Box>
  )
};

export default SignatureImage;