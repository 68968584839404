import React, { useMemo, useRef } from 'react';
import "./textEditor.css";
import JoditEditor, { Jodit } from 'jodit-react';
import { useState } from "react";
import { Box, Button, ClickAwayListener, Fade, IconButton, Modal, Paper, Popper, Typography } from "@mui/material";
import { getRephrase } from "../../Api/ParaphraseApi";
import { toggleLoader } from "../../store/loader/loaderReducer";
import { toast } from "react-toastify";
import { rephraseDetail } from "../../store/paraphrase/rephraseReducer";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { pdfDetail } from '../../store/paraphrase/pdfReducer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: '70%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
    padding: '32px 30px 15px 32px'

};


const TextEditor = ({ steps }) => {
    const dispatch = useDispatch();
    const paraphraseValue = useSelector((state) => state?.paraphraseDetails?.value?.data);

    const rephraseValue = useSelector((state) => state?.rephraseDetails?.value?.data?.response);
    const documentValue = useSelector((state) => state.document.value);
    const loader = useSelector((state) => state.loader.isLoading);

    const [selected, setSelected] = useState({ text: '' })
    const [fullDocument, setFullDocument] = useState(false)
    const [value, setValue] = useState('')

    const [modalopen, setModalOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const editors = useRef(null);

    const config = useMemo(() => {
        return {
            "toolbarAdaptive": false,
            "toolbarSticky": false,
            buttons: ['bold', 'italic', 'underline', 'strikethrough', 'link', 'image', 'ul', 'ol', 'undo', 'redo', 'source', 'table'],
            "useSearch": false,
            "showCharsCounter": false,
            "showWordsCounter": false,
            "showXPathInStatusbar": false,
            "askBeforePasteHTML": false,
            "enter": "BR",
            'disablePlugins': 'enter',
        };
    }, [])
    const addBreaks = (text) => {
        return text.replace(/\n/g, '<br>');
    }
    const spaceRemove = (text) => {
        // return text.replace(/\s*<br>/g, '<br>');
        return text.replace(/<br>\s*/g, '<br>');
    }

    useEffect(() => {

        let removespace = paraphraseValue.response.split('\n\n').join('<br><br>')
        setValue(removespace.split('\n').join('<br>'))
    }, [paraphraseValue]);

    const handleReplaceText = () => {
        const selection = window.getSelection().toString();
        // const selected = selection.split('\n\n').join('<br><br>')
        setSelected((selecte) => ({ ...selecte, text: selection }))
    };

    const modifyText = (newText) => {
        if (!fullDocument) {

            const selectedText = addBreaks(selected.text)
            const valueText = spaceRemove(value)
            const newTextResult = valueText.replace(selectedText, newText)

            setValue(newTextResult);
            setSelected({ text: '' })
        }
    };

    useEffect(() => {
        if (selected.text.length) {
            modifyText(rephraseValue)
        }

    }, [rephraseValue]);

    const approveDocument = () => {
        setFullDocument(false)
        let removespace = rephraseValue.split('\n\n').join('<br><br>')
        setValue(removespace.split('\n').join('<br>'))
    }

    const handleRephrase = (e) => {
        setAnchorEl(null)
        setOpen(false)
        let data;
        if (e === 'entire document') {
            setFullDocument(true)
            data = {
                org_id: documentValue?.OrgId,
                text: paraphraseValue.response,
            };
        }
        else {
            setFullDocument(false)
            data = {
                org_id: documentValue?.OrgId,
                text: selected.text,
            };
        }
        dispatch(toggleLoader(true));

        getRephrase(
            data,
            (res) => {
                dispatch(toggleLoader(false));
                dispatch(rephraseDetail({ data: res.data }))
            },
            (err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            }
        );
    }

    const handleProceed = () => {
        dispatch(pdfDetail({ data: editors.current.value }));
        steps((prevValue) => prevValue + 1)
    }
    const handleBack = () => {
        steps((prevValue) => prevValue - 1);
    }
    const handleClickAway = (event) => {
        setAnchorEl(null)
        setOpen(false)
    };

    const handleClick = (event) => {
        setOpen(!open)
        setAnchorEl(event.currentTarget);
        handleReplaceText();
    };

    return (
        <div className='jodit_top'>

            <div className='jodit_table'>
                {paraphraseValue.response && <JoditEditor
                    ref={editors}
                    value={value}
                    config={config}
                    tabIndex={-1}
                />}
                {fullDocument && !loader &&
                    <Modal
                        open={modalopen}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{ ...style, maxWidth: '90vw', maxHeight: '90vh', overflowY: 'auto' }}>
                            <Box className="document_title">
                                <Box>  <div>What exactly should the new section look like?</div></Box>

                                <IconButton onClick={() => setFullDocument(false)} size="small">
                                    <ClearIcon />
                                </IconButton>

                            </Box>
                            <textarea className="model_text_area" disabled>{rephraseValue}</textarea>
                            <Box>
                                <Box className={"approve_btn"} sx={{ textAlign: 'right', marginTop: '1rem' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => approveDocument()}

                                    >
                                        <CheckIcon />
                                        Approve
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                }
            </div >
            <div className='jodit_buttons'>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <Popper
                            disablePortal={true}
                            open={!!anchorEl} anchorEl={anchorEl} placement={'top'} transition sx={{ zIndex: 10 }}>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper style={{ padding: '10px' }} className="quill_paper">
                                        <Typography className="text_settings">SETTINGS</Typography>
                                        <Typography className="document">
                                            <span><img
                                                src={
                                                    process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/entiredocument.svg`
                                                }
                                            /></span>
                                            <span onClick={() => handleRephrase('entire document')}>Rephrase entire document</span>
                                        </Typography>
                                        <Typography className={selected.text.split(" ").length >= 20 ? "document" : 'document_unselect'}>
                                            <span><img
                                                src={
                                                    process.env.REACT_APP_IMAGE_URL + `${selected.text.split(" ").length >= 20 ? `TemplatesSvg/rephrasesection.svg` : `TemplatesSvg/rephrasesectiondisable.svg`}`
                                                }
                                            /></span>
                                            <span onClick={() => { selected.text.split(" ").length >= 20 && handleRephrase() }} style={{ color: selected.text.split(" ").length >= 20 || '#bfbfc9' }}>Rephrase a section</span>
                                        </Typography>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                        <div className={"rephrase_btn"}>
                            <Button
                                disabled={loader}
                                variant="outlined"
                                onClick={(e) => handleClick(e)}>
                                {loader ? <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/RephraseDisable.svg`} /> : <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/Rephrase.svg`} />}
                                Rephrase Again
                            </Button>
                        </div>
                    </div>
                </ClickAwayListener>
                <div>
                    <Button
                        variant="outlined"
                        disabled={loader}
                        disableElevation
                        disableRipple
                        onClick={() => handleBack()}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        disabled={loader}
                        disableElevation
                        disableRipple
                        onClick={() => handleProceed()}
                    >
                        Proceed
                    </Button>
                </div>
            </div>
        </div >
    )
}
export default TextEditor