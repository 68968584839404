import React, { useEffect, useRef, useState } from 'react'
import styles from "./Filter.module.css";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, FormControl, MenuItem, Select, TextField } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';

const Filter = ({ filterExpand, orgtHead, filterdata }) => {
    const [expand, setExpand] = useState(false);
    const [idCount, setIdCount] = useState(1);
    const [filters, setfilters] = useState([{ id: 1, columns: '', condition: '=', value: 1, operator: 'and' }])
    const [isHovered, setIsHovered] = useState(false);



    const statusValue = ['AWAIT', 'APPROVED', 'SENT', 'FAILED', 'LATER', 'SEND_NOW'];
    const dateTypes = ['TODAY', 'YESTERDAY', 'THIS_WEEK', 'LAST_WEEK', 'THIS_MONTH', 'LAST_MONTH', 'THIS_YEAR', 'LAST_YEAR'];
    const DateValues = ["Start_Time", "Created_Time", "Modified_Time", 'Timestamp'];

    useEffect(() => {
        const value = filters;
        value[0].columns = orgtHead[0]
        setfilters(value)
    }, [orgtHead[0]]);

    const handleSetColumns = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, columns: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetCondition = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, condition: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetValue = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, value: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetOperator = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, operator: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleAddfilter = (e) => {
        setIdCount(idCount + 1)
        const newFilter = { id: idCount + 1, columns: orgtHead[0], condition: "=", value: 0, operator: "and" };
        setfilters([...filters, newFilter]);
    }

    const handleRemoveFilter = (id) => {
        const updatedInputs = filters.filter((filter) => filter.id !== id);
        setfilters(updatedInputs);
    }


    const sendDataToTable = () => {

        filterdata(filters.map((filter) => {
            if (filters[0].id === filter.id) {
                const { operator, ...rest } = filter;
                return rest;
            }
            return filter;
        }))

        setExpand(false);
    }

    const clearFilter = () => {
        setExpand(false);
        setfilters([{ id: 1, columns: orgtHead[0], condition: '=', value: '', operator: 'and' }])
        filterdata([])
    }

    return (
        <div style={{ position: 'relative', top: '130px' }}>
            {/* <div className={filterExpand ? expand ? styles.filter_close : styles.filter_open : styles.filter_hide} > */}
            <div className={expand ? styles.filter_close : styles.filter_open} >
                <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => setExpand(!expand)} className={styles.filter_top}>
                    {isHovered ? expand ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon /> : <FilterAltIcon />}
                </div>
                <div className={expand ? styles.top_selecters : styles.top_selecters_none}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}><div>Filter</div><div onClick={() => setExpand(!expand)}><ClearIcon /></div></div>
                    <div className={styles.filters_array}>
                        {filters.map((filter) => (
                            <div key={filter.id}
                                style={{ justifyContent: filters.length === 1 ? "space-around" : "flex-end" }}
                                className={styles.selectors}>
                                {(filters[0].id !== filter.id ? < FormControl size="small" >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"

                                        value={filter.operator}
                                        onChange={(e) => handleSetOperator(filter.id, e.target.value)}
                                        style={{ height: 35, width: '170px' }}
                                    >
                                        <MenuItem value={"and"}>AND</MenuItem>
                                        <MenuItem value={"or"}>OR</MenuItem>
                                    </Select>
                                </FormControl> :filters.length>1 && <Box style={{textAlign: 'center', height: 35, width: '170px', }}>Where</Box>)}
                                <FormControl size="small" >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filter.columns}
                                        onChange={(e) => handleSetColumns(filter.id, e.target.value)}
                                        style={{ height: 35, width: '170px' }}
                                    >
                                        {orgtHead?.map((head, i) => (<MenuItem key={i} value={head}>{head}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                <FormControl size="small" >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filter.condition}
                                        onChange={(e) => handleSetCondition(filter.id, e.target.value)}
                                        style={{ height: 35, width: '170px' }}
                                    >
                                        <MenuItem value={"="}>EQUAL</MenuItem>
                                        <MenuItem value={"!="}>NOT EQUAL</MenuItem>
                                        <MenuItem value={"<"}>LESS THAN</MenuItem>
                                        <MenuItem value={">"}>GREATER THAN</MenuItem>
                                        <MenuItem value={"<="}>LESS THAN OR EQUAL</MenuItem>
                                        <MenuItem value={">="}>GREATER THAN OR EQUAL</MenuItem>
                                    </Select>
                                </FormControl>
                                {filter.columns === "Status" ?
                                    <FormControl size="small" >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filter.value}
                                            onChange={(e) => handleSetValue(filter.id, e.target.value)}
                                            style={{ height: 35, width: '170px' }}
                                            defaultValue={0}
                                        >
                                            {statusValue.map((item, i) => (< MenuItem value={i}>{item}</MenuItem>))}
                                        </Select>
                                    </FormControl> :
                                    DateValues.includes(filter.columns) ?
                                        (<FormControl size="small" >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filter.value}
                                                onChange={(e) => handleSetValue(filter.id, e.target.value)}
                                                style={{ height: 35, width: '170px' }}
                                                defaultValue={1}
                                            >
                                                {dateTypes.map((item, i) => (< MenuItem value={i + 1}>{item}</MenuItem>))}
                                            </Select>
                                        </FormControl>) :
                                        (<FormControl size="small" >
                                            <TextField sx={{ width: '170px' }} style={{ height: 35 }} onChange={(e) => handleSetValue(filter.id, e.target.value)} value={filters.value} placeholder='Value' id="outlined-basic" variant="outlined" />
                                        </FormControl>)

                                }

                                <FormControl size="small" >
                                    {filters.length > 1 && <Button variant="contained" onClick={() => handleRemoveFilter(filter.id)}>Delete</Button>}
                                </FormControl>
                            </div>))}</div>
                    <div className={styles.filter_sub_button}>
                        <div>
                            <Button variant="contained" onClick={handleAddfilter}>Add</Button>
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Button variant="contained" onClick={() => clearFilter()}>Clear</Button>
                            <Button variant="contained" onClick={sendDataToTable}>Apply</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Filter